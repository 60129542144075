import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../../elements/typography'
import wrapUrlInTextInAnchor from '@/core/utils/wrapLinkAnchor'

const Wrapper = styled.div`
  margin-bottom: 9px;
  display: table;
  height: 52px;
  background-color: #f2f4f6;
  width: 100%;
  text-align: left;

  > * {
    white-space: normal;
  }

  .titleContainer {
    padding-left: 27px;
    display: inline-block;
    vertical-align: middle;
    width: ${(props) => `${props.colWidth}% !important`};
    padding: 8px 22px;
    background-color: #f2f4f6;
    min-height: 52px;

    &.header {
      > div {
        font-weight: 600 !important;
      }
    }
  }
  .priceColumn {
    display: inline-block;
    vertical-align: middle;
    width: ${(props) => `${props.colWidth}% !important`};
    text-align: left;
    padding: 8px 22px;
    background-color: #f2f4f6;
    min-height: 52px;
    &:last-child {
      width: 400px;
    }
  }

  @media screen and (max-width: 1000px) {
    max-width: 100%;
    height: unset;
    display: block;
    .titleContainer {
      width: ${(props) => `${props.colWidth}% !important`};
      padding: 4px 11px;
      > div {
        font-size: 14px;
      }
    }
    .priceColumn {
      width: ${(props) => `${props.colWidth}% !important`};
      padding: 4px 11px;
      > div {
        font-size: 14px;
      }
      &:last-child {
        width: 100%;
      }
    }
  }
`

const FacilityPrice = ({ title, prices, colWidth, firstIsHeader }) => {
    return (
        <Wrapper colWidth={colWidth}>
            <div className={`titleContainer ${firstIsHeader ? ' header' : ''}`}>
                <TypographyElement variant="share">{wrapUrlInTextInAnchor(title)}</TypographyElement>
            </div>
            {prices?.map((price, i) => {
                const value = wrapUrlInTextInAnchor(price);
                return (
                    <div
                        className={`priceColumn${price ? '' : ' empty'}`}
                        key={`price-${i}`}>
                        <TypographyElement variant="share">
                            {value}
                        </TypographyElement>
                    </div>
                )
            })}
        </Wrapper>
    )
}

export default FacilityPrice
