import { innerHtml } from "./innerHtml";

const wrapUrlInTextInAnchor = (text) => {
    if (!text) return text;
    const linkRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const matches = text.match(linkRegex);

    if (matches) {
        matches.forEach((match) => {
            let href = match;
            if (!match.includes("http")) {
                href = `//${match}`;
            }
            text = text.replace(
                match,
                `<a href="${href}" target="_blank" rel="noopener noreferrer">${match}</a>`
            );
        });
    }
    return innerHtml(text);
};

export default wrapUrlInTextInAnchor;
