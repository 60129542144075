import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'
import { grey, lightgrey_background, white } from '../../colors'

import ListItemElement, { ListCursiveItem } from '../../elements/listitem'
import TypographyElement from '../../elements/typography'

const Wrapper = styled.div`
  box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
    0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
    0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
  background: ${(props) => props.background || white};
  padding: 29px 32px 29px 40px;

  .links {
    padding-top: 10px;
    margin: 0;
    .MuiListItem-root {
      padding-right: 0;
      a[variant='arrowAfter'] {
        > * {
          display: inline-block;
          vertical-align: middle;
        }
        svg {
          margin-left: 19px;
          width: 15px;
        }
      }
    }
  }
`

const InfoLinkList = ({ title, links, items }) => {
  const theme = useTheme()
  const background =
    theme?.name == 'subsite' &&
    theme.globalTheme?.palette?.backgroundColor?.light
  if (!links) {
    links = items
  }
  return (
    <Wrapper background={background}>
      <TypographyElement component="h2" variant="infoListTitle">
        {title}
      </TypographyElement>
      <ul className="links">
        {links?.map((link, i) => {
          const href = link?.email ? `mailto:${link.email}` : link.href
          return href ? (
            <ListItemElement
              {...link}
              href={href}
              text={link.title || link.text}
              variant={href ? 'arrowAfter' : 'cursive'}
              key={`listitem-${i}`}
            />
          ) : (
            <ListCursiveItem
              {...link}
              text={link.title || link.text}
              isCursive={true}
              key={`listitem-${i}`}
            />
          )
        }) || null}
      </ul>
    </Wrapper>
  )
}

export default InfoLinkList
