import React from 'react'
import styled from 'styled-components'
import TypographyElement from '../../elements/typography'
import { innerHtml } from '../../utils/innerHtml'
import ArticleImages from '../../modules/_subsite/articleImages'

const SectionWrapper = styled.div`
  padding-bottom: ${(props) => `${props.bottomSpacing}px`};
  width: ${(props) => (props.narrow ? 'calc(100% - 340px)' : '100%')};

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding-bottom: 20px;
  }
`

export const SectionElement = ({
  title,
  text,
  images,
  bottomSpacing = 50,
  narrow = false,
  isFirst = false,
}) => {
  return (
    <SectionWrapper
      className="text-section"
      bottomSpacing={bottomSpacing}
      narrow={narrow}
    >
      {title && (
        <TypographyElement variant="title" component={isFirst ? 'h1' : 'h2'}>
          {title}
        </TypographyElement>
      )}
      {text && innerHtml(text)}
      {images && images.length > 0 && (
        <ArticleImages images={images} narrow={narrow} />
      )}
    </SectionWrapper>
  )
}
