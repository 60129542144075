'use client'
import React from 'react'
import styled from 'styled-components'

import ImageBuilder from '../../../elements/imageBuilder'

const Wrapper = styled.div`
  margin-bottom: 46px;
  .imagesContainer {
    overflow: hidden;
    width: ${(props) =>
      props.narrow ? `calc(100% + 340px) !important` : `100%`};
    > span {
      height: 374px !important;
      img {
        object-position: center;
        object-fit: cover;
        width: 100%;
      }
    }
    ${(props) =>
      props.images == 1 &&
      `
            > span {
                width: 100% !important;
            }
        `}
    ${(props) =>
      props.images == 2 &&
      `
            > span {
                width: calc(50% - 15px) !important;
                :first-child {
                    margin-right: 30px !important;
                }
            }
        `}
        ${(props) =>
      props.images == 3 &&
      `
            > span {
                width: calc(33.3% - 20px) !important;
                margin-right: 30px !important;
                :nth-child(3n+3) {
                    margin-right: 0 !important;
                }
            }
        `}
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: 30px;
    .imagesContainer {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
  }
`

const ArticleImages = ({ images, narrow = false }) => {
  const amount = images.length
  const cols = amount > 3 ? 3 : amount
  const width = 1326 / cols - 30 * (amount - 1)
  const height = 374

  return (
    <Wrapper images={cols} narrow={narrow}>
      <div className="imagesContainer">
        {images?.map((image, i) => {
          return (
            <ImageBuilder
              image={image}
              alt={image.title}
              width={width}
              height={height}
              key={`image-${i}`}
            />
          )
        })}
      </div>
    </Wrapper>
  )
}

export default ArticleImages
