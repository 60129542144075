'use client'
import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../../elements/typography'
import FacilityPrice from '../../../compositions/_subsite/facilityPrice'
import { innerHtml } from '../../../utils/innerHtml'

const Wrapper = styled.div`
  margin-bottom: 70px;
  h3 {
    padding-bottom: 22px;
  }
  .columnTitles {
    margin-bottom: 9px;
    .blank {
      padding-left: 27px;
      display: inline-block;
      vertical-align: middle;
      width: 414px;
    }
    .column {
      padding: 8px 22px;
      display: inline-block;
      vertical-align: middle;
      width: ${(props) => `${props.colWidth}%`};
      text-align: left;
      word-break: auto-phrase;
      white-space: break-spaces;
    }
  }

  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;

  @media screen and (max-width: 1000px) {
    margin-bottom: 40px;
    > .tableWrapper {
      /* Make tables scrollable */
      width: 100%;
      overflow-x: scroll;
      > .scrollable {
        min-width: 600px;
      }
    }
    .columnTitles {
      .blank {
        width: ${(props) => `${props.colWidth}%`};
        padding: 4px 11px;
        > div {
          font-size: 14px;
        }
      }
      .column {
        width: ${(props) => `${props.colWidth}%`};
        padding: 4px 11px;
        white-space: normal;
        > div {
          font-size: 14px;
        }
      }
    }
  }
`

const FacilityPrices = ({ title, table, description }) => {
  const items = table?.data || []
  let columns = []
  let prices = []
  let cols = (items[0] || []).length || 1
  if (table?.first_row_is_table_header) {
    columns = items[0]
    prices =
      items?.slice(1)?.map((row) => {
        const [title, ...rest] = row
        return {
          title,
          prices: rest,
        }
      }) || []
  } else {
    prices =
      items?.map((row) => {
        const [title, ...rest] = row
        return {
          title,
          prices: rest,
        }
      }) || []
  }
  const colCount = cols
  const colWidth = 100 / colCount
  return (
    <Wrapper colWidth={colWidth}>
      <TypographyElement variant="infoListTitle" component="h3">
        {title}
      </TypographyElement>
      <div className="tableWrapper">
        <div className="scrollable">
          {columns?.length > 1 && (
            <div className="columnTitles">
              {columns?.map((column, i) => {
                return (
                  <div className="column" key={`kolom-${i}-${column}`}>
                    {column}
                  </div>
                )
              })}
            </div>
          )}
          {prices?.map((item, i) => {
            return (
              <FacilityPrice
                {...item}
                colWidth={colWidth}
                key={`facility-price-${i}`}
                firstIsHeader={table?.first_col_is_header}
              />
            )
          })}
          {description ||
            (table?.table_caption && (
              <TypographyElement variant="share">
                {innerHtml(description || table?.table_caption)}
              </TypographyElement>
            ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default FacilityPrices
