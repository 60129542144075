import React from 'react'
import { ListItem, ListItemButton, ListItemText } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SvgElement from './svg'
import { shouldOpenNewTab } from '../utils/shouldOpenNewTab'

export const ExternalAwareListItemButton = ({ children, href, ...props }) => {
  const [externalProps, setExternalProps] = React.useState({})
  React.useEffect(() => {
    const external = shouldOpenNewTab(href)
    setExternalProps(
      external
        ? {
            target: '_blank',
            rel: 'noopener',
          }
        : {}
    )
  }, [])

  return (
    <ListItemButton {...externalProps} {...props} href={href}>
      {children}
    </ListItemButton>
  )
}

const ListButton = ({
  text,
  href,
  handleClick,
  component = 'a',
  isFilter = false,
}) => {
  return (
    <ListItemButton
      component={component}
      href={href}
      onClick={handleClick}
      variant="primary"
    >
      <ListItemText primary={text} isfilter={isFilter ? 'true' : ''} />
    </ListItemButton>
  )
}

const ListText = ({ text, isFilter = '' }) => {
  return <ListItemText primary={text} isfilter={isFilter ? 'true' : ''} />
}

export const ListCursiveItem = ({ text, isFilter = false, ...restProps }) => {
  return (
    <ListItemText
      primary={text}
      isfilter={isFilter ? 'true' : ''}
      {...restProps}
    />
  )
}

export const ListArrowLink = ({
  text,
  href,
  handleClick,
  component = 'a',
  isFilter = false,
}) => {
  return (
    <ExternalAwareListItemButton
      component={component}
      href={href}
      onClick={handleClick}
      variant="arrow"
    >
      <ArrowForwardIcon />
      <ListItemText
        primary={text}
        nomarker="true"
        isfilter={isFilter ? 'true' : ''}
      />
    </ExternalAwareListItemButton>
  )
}

export const ListArrowAfterLink = ({
  text,
  href,
  handleClick,
  component = 'a',
  isFilter = false,
}) => {
  return (
    <ExternalAwareListItemButton
      component={component}
      href={href}
      onClick={handleClick}
      variant="arrowAfter"
    >
      <ListItemText
        primary={text}
        nomarker="true"
        isfilter={isFilter ? 'true' : ''}
      />
      <ArrowForwardIcon width={10} />
    </ExternalAwareListItemButton>
  )
}

export const ListIconLink = ({
  text,
  href,
  icon,
  handleClick,
  component = 'button',
  isFilter = false,
  ...restProps
}) => {
  return (
    <ExternalAwareListItemButton
      component={component}
      href={href}
      onClick={handleClick}
      variant="arrow"
      {...restProps}
    >
      <SvgElement variant={icon} />
      <ListItemText
        primary={text}
        nomarker="true"
        isfilter={isFilter ? 'true' : ''}
      />
    </ExternalAwareListItemButton>
  )
}

export const ListNoMarkerLink = ({
  text,
  href,
  handleClick,
  isFilter = false,
  component = 'button',
  ...restProps
}) => {
  return (
    <ExternalAwareListItemButton
      component={component}
      href={href}
      onClick={handleClick}
      variant="nomarker"
      {...restProps}
    >
      <ListItemText
        primary={text}
        nomarker="true"
        isfilter={isFilter ? 'true' : ''}
      />
    </ExternalAwareListItemButton>
  )
}

export const ListUppercaseLink = ({
  text,
  href,
  handleClick,
  isFilter = false,
  component = 'button',
  ...restProps
}) => {
  return (
    <ExternalAwareListItemButton
      component={component}
      href={href}
      onClick={handleClick}
      variant="uppercase"
      {...restProps}
    >
      <ListItemText
        primary={text}
        nomarker="true"
        isfilter={isFilter ? 'true' : ''}
        texttransform="uppercase"
      />
    </ExternalAwareListItemButton>
  )
}

export const SubsiteContactInfoItem = ({
  text,
  href,
  icon,
  handleClick,
  component = 'a',
  isFilter = false,
  ...restProps
}) => {
  return (
    <ExternalAwareListItemButton
      component={component}
      href={href}
      isinfo={'true'}
      onClick={handleClick}
      variant="arrow"
      {...restProps}
    >
      {icon}
      <ListItemText primary={text} nomarker="true" isinfo={'true'} />
    </ExternalAwareListItemButton>
  )
}

const linkListDict = {
  normal: ListButton,
  arrow: ListArrowLink,
  arrowAfter: ListArrowAfterLink,
  icon: ListIconLink,
  nomarker: ListNoMarkerLink,
  uppercase: ListUppercaseLink,
  // 'cursive': ListCursiveItem,
}

const ListItemElement = ({
  title,
  text,
  href,
  handleClick,
  icon,
  variant = 'normal',
  ...restProps
}) => {
  const Element =
    !href && !handleClick
      ? ListText
      : icon
      ? ListIconLink
      : linkListDict[variant]

  const noLeftPadding = [
    'nomarker',
    'uppercase',
    'arrowAfter',
    'arrow',
  ].includes(variant)
  return (
    <ListItem noleftpadding={noLeftPadding ? 'true' : ''}>
      <Element
        text={text || title}
        href={href}
        icon={icon}
        handleClick={handleClick}
        {...restProps}
      />
    </ListItem>
  )
}

export default ListItemElement
